import domReady from '@wordpress/dom-ready';
import { _x } from '@wordpress/i18n';

domReady(() => {
  const popups = document.querySelectorAll('.popup-form-wrapper');

  if (0 < popups.length) {
    popups.forEach((popup) => {
      const toggle = popup.querySelector('.popup-form-opener');
      const popupContent = popup.querySelector('.popup-form__content');
      const buttonLabel = popup.querySelector('.popup-form-opener__label span');
      const textClosed = buttonLabel.textContent;
      const textOpened = _x('Close', 'popup form label button close', 'vtx');

      if (toggle) {
        toggle.addEventListener('click', () => {
          if (popup.classList.contains('opened')) {
            popup.classList.remove('opened');
            toggle.setAttribute('aria-expanded', 'false');
            popupContent.setAttribute('aria-hidden', 'true');
            buttonLabel.textContent = textClosed;
          } else {
            const otherPopup = document.querySelector(
              '.popup-form-wrapper.opened'
            );
            popup.classList.add('opened');
            toggle.setAttribute('aria-expanded', 'true');
            popupContent.setAttribute('aria-hidden', 'false');
            buttonLabel.textContent = textOpened;

            if (otherPopup) {
              const label = otherPopup
                .querySelector('.popup-form-opener')
                .getAttribute('data-text');
              otherPopup.classList.remove('opened');
              otherPopup
                .querySelector('.popup-form-opener')
                .setAttribute('aria-expanded', 'false');
              otherPopup
                .querySelector('.popup-form__content')
                .setAttribute('aria-hidden', 'true');
              otherPopup.querySelector(
                '.popup-form-opener__label span'
              ).textContent = label;
            }
          }
        });
      }
    });
  }
});
